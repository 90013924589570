import { React, useState, useRef } from "react";

import "./accordian.scss";
import Chevron from "./chevron";

function Accordian(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordian__icon rotate");

  const content = useRef(null);

  function toggleAccordian() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(setActive === "active" ? "0px" : `${content.current.scrollHeight}px`);
    setRotateState(setActive === "active" ? "accordian__icon rotate" : "accordian__icon");
  }

  return (
    <div className="acc-or">
      <div className="accordian__section">
        <a className={`accordian ${setActive}`} onClick={toggleAccordian}>
          <p className="accordian__title">
            {props.title}
          </p>
        </a>
        <div ref={content} style={{ maxHeight: `${setHeight}` }} className="accordian__content">
          <div className="accordian__text1" dangerouslySetInnerHTML={{ __html: props.content }} />
        </div>
      </div>
      <Chevron className={`${setRotate}`} width={'.8em'} fill={"#777"} onClick={toggleAccordian}/>
      <div className="bor-hr"></div>
    </div>
  );
}

export default Accordian;
