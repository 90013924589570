import Footer from "./footer";
import { Component } from "react";
import "./form.scss";
import Header from "./header";

export default class From extends Component {
  constructor(props) {
    super();
    this.submitButton = <button className="disabled" id="submit" type="submit" onClick={this.submit}>Submit</button>
    window.addEventListener("app-resize", (e) => {
      this.forceUpdate();
    });

  }
  send = false;

  verify = () => {
    let form = document.getElementById('form');
    let inputs = form.querySelectorAll('[name]')
    this.send = true;
    let details = {};
    details.time = new Date();
    details.time = details.time.toISOString().substring(0, 10);
    details.action = 'post';
    details.sheet_name = 'wiom_in_website_form';
    details.to_address = 'maanas.dwivedi@i2e1.com';
    
    inputs.forEach((inp, key) => {
      console.log(inp.name, inp.value, key)
      details[inp.name] = inp.value
      if (inp.hasAttribute('required') && !inp.value) {
        console.log(inp.name, ' is required')
        this.send = false;
      }
    })
    if (this.send) {
      this.sendDetails = details;
      document.getElementById('submit').classList.remove('disabled')
    }
  }

  submit = (eve) => {
    if (this.send) {
      this.toSpreadSheet(this.sendDetails);
    }
  }
  handleSuccess = () => {
    this.submitButton = <h4>Details Submitted</h4>
    this.forceUpdate()
  }

  toSpreadSheet = (data) => {
    this.submitButton = <h4>Submitting...</h4>;
    this.forceUpdate()
    data.action = 'post'
    console.log('adding record to google sheet')
    let getParams = "";
    Object.keys(data).forEach(key => {
      getParams += `&${key}=${data[key]}`;
    })
    getParams = getParams.substring(1);
    let script_url = "https://script.google.com/macros/s/AKfycbyihJ1dt0qwzKpW3pP2iJCEC_sOQM0cnFI0ZatWim6RFktdjNUjnlOQnA/exec";
    let url = script_url + "?" + getParams;
    fetch(url).then(() => {
      this.handleSuccess()
    });
  }

  render = () => {
    return (
      <>
        <Header for={this.props.for || this.props.match.params.for} bg="no-bg" hideButton="true" />
        <div className="form-main">
          <img
            className="form-bg-img"
            src={`${process.env.PUBLIC_URL}/appbg.jpg`}
          />
          <div className="form-main-heading">
            <h1>Join the Digital India Mission</h1>
            <h1>Send us a note</h1>
          </div>
          <div className="form-outer">
            <div className="form-outer-heading">
              <h1 style={{ fontWeight: "normal" }}>
                Fill out this form and we
              </h1>
              <h1 style={{ fontWeight: "bold" }}>
                will reply within 1 business day
              </h1>
            </div>
            <div className="form-inner" id="form">
                <h4>Your Name</h4>
              <input placeholder="" required name="name" onChange={ () => this.verify() }/>
                <h4>Company name</h4>
                <input placeholder="" name="companyName" onChange={ () => this.verify() }></input>
                <h4>Phone Mobile number</h4>
                <input type="number" placeholder="" required name="number" onChange={ () => this.verify() }></input>
                <h4>Email</h4>
                <input type="email" placeholder="" name="email" onChange={ () => this.verify() }></input>
                <h4>Your Query</h4>
                <textarea placeholder="" name="query" id="query" onChange={ () => this.verify() }
                  style={{
                    border: "2px solid #e9e9e9",
                    width: "90%",
                    height: "4em",
                  }}
              ></textarea>
              {this.submitButton }
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  };
}
