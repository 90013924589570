import { React } from "react";
import "./drawer.scss";
import { Link } from "react-router-dom";

export default function Drawer(props) {
  let toggle = () => {
    document.getElementById("drwr").classList.toggle("open");
  };
  let color = props.for == 'pdo' ? '#F4693A' : '#0839FB';
  return (
    <div className="drawer" id="drwr">
      <div className="backdrop" onClick={toggle}></div>
      <a className="ham visible-in-small" onClick={toggle}>
        <hr style={{backgroundColor: color}}/>
        <hr style={{backgroundColor: color}}/>
        <hr style={{backgroundColor: color}}/>
      </a>
      <div className="drawer-main" id="drawermain">
        <div className="clicks">
          <a href={`/${props.for}/learning-center/blogs`}>Blogs</a>
          <a href={`/${props.for}/learning-center/knowledge_sharing`}>Knowledge centre</a>
          <a href={`/${props.for}/learning-center/how_to_guides`}>‘How to’ Guides</a>
          <a href={`/${props.for}/learning-center/faqs`}>FAQs</a>
          <a href={`/${props.for}/learning-center/news`}>In News</a>
          <a href="https://www.i2e1.com/tribe.html" target="_blank">
            Team
          </a>
        </div>
        <hr
          style={{
            width: "80%",
            margin: "3em 0",
            color: "red",
            borderColor: "rgba(255, 255, 255, 0.2)",
          }}
        />
        <div className="contacts">
          <div className="contact">
            <img
              src={`${process.env.PUBLIC_URL}/svgs/foot-call.svg`}
              alt="callme"
            />
            <p>8585-919-668</p>
          </div>
          <div className="contact">
            <img
              src={`${process.env.PUBLIC_URL}/svgs/foot-call.svg`}
              alt="callme"
            />
            <p>8585-919-668</p>
          </div>
          <div className="contact">
            <img
              src={`${process.env.PUBLIC_URL}/svgs/foot-email.svg`}
              alt="callme"
            />
            <p>contact@i2e1.com</p>
          </div>
          <div className="contact">
            <Link className="white-cta" to={`/${props.for}/learning-center/status_certification`}>PDO Status &amp; Certification</Link>
          </div>
        </div>
        <div className="lower">
          <div className="visible-in-small">
            <a href="https://www.facebook.com/wiombyi2e1" target="_blank">
              <img src={`${process.env.PUBLIC_URL}/svgs/facebook.svg`} />
            </a>
            <a href="https://twitter.com/i2e1official" target="_blank">
              <img src={`${process.env.PUBLIC_URL}/svgs/twitter.svg`} />
            </a>
            <a href="https://www.instagram.com/i2e1_official/" target="_blank">
              <img src={`${process.env.PUBLIC_URL}/svgs/insta.svg`} />
            </a>
          </div>
          <h2 className="visible-in-small">Wiom by i2e1, 2021</h2>
          <div className="copyright">© Omnia Information pvt. ltd</div>
        </div>
      </div>
    </div>
  );
}
