import { React } from "react";
import "./learningCenter.scss";
import blogscolumn1 from "../assets/media/blogscolumn1.png";
import blogscolumn2 from "../assets/media/blogscolumn2.png";

import et from "../assets/media/in-news/et.png";
import inc from "../assets/media/in-news/inc.png";
import danik from "../assets/media/in-news/danik.png";
import bs from "../assets/media/in-news/bs.png";
import yourstory from "../assets/media/in-news/yourstory.png";
import livemint from "../assets/media/in-news/livemint.png";
import Accordian from "./accordian";
import Header from "./header.js";
import Footer from "./footer.js";
import { Component } from "react";
import YTWrapper from "./yt-video";
import StatusCertification from "./statusCertification";

const headingCardContent = [
  { title: "Blogs", scrollID: "blogs" },
  { title: "Knowledge Sharing", scrollID: "knowledge_sharing" },
  { title: "How to Guides", scrollID: "how_to_guides" },
  { title: "FAQs", scrollID: "faqs" },
  { title: "News", scrollID: "news" },
];
const newsdata = [
  {
    image: et,
    link: "https://telecom.economictimes.indiatimes.com/news/delhi-based-startup-i2e1-launches-first-pdo-under-pm-wani-initiative/80450032",
    heading:
      "Delhi-based startup i2e1 launches first PDO under PM-Wani initiative ",
    subheading:
      "According to the company, the PDO which has beenestablished in South Delhi's Kusumpur Pahadi area, will allow the area residents...",
  },
  {
    image: inc,
    link: "https://inc42.com/startups/i2e1-turns-kiranas-into-wi-fi-hotspots-can-it-take-on-cheap-4g-plans/",
    heading:
      "This Start-up Wants To Turn Kiranas Into Wi-Fi Hotspots, But Will Cheap Mobile Internet Kill Its Dream? ",
    subheading:
      "We want to make internet access so affordable that people will pick a plan ins...",
  },
  {
    image: danik,
    image2: <img src={`${process.env.PUBLIC_URL}/pngs/dainik_bhaskar.png`} style={{ marginBottom: '1em', width: '100%', height: 'auto', display: 'block' }}/>,
    cta: "full view",
    link: `${process.env.PUBLIC_URL}/pngs/dainik_bhaskar.png`
  },
  {
    image: bs,
    link: "https://www.business-standard.com/article/companies/i2e1-wifi-on-a-sharing-platform-117120300767_1.html",
    heading: "i2e1: Wifi on a sharing platform",
    subheading:
      "Despite serious efforts by the government to make the Internet available to everyone at a low cost, a significant gap exists in wifi proliferation. Advertisement-based or tele...",
  },
  {
    image: yourstory,
    link: "https://yourstory.com/2017/04/i2e1-free-internet-startup",
    heading:
      "i2e1: Satyam Darmora's gambit which delivered free internet in 4,000 locations ",
    subheading:
      "There is so much bandwidth that goes waste in India that by just tapping into a small portion available with retailers there can be free In...",
  },
  {
    image: livemint,
    link: "https://www.livemint.com/Companies/hCYNCNgLz8IctWYz6zYHQI/WiFi-analytics-startup-i2e1-raises-3-million-from-Omidyar.html",
    heading:
      "Wi-Fi analytics start-up i2e1 raises $3 million from Omidyar Network, others ",
    subheading:
      "Wi-Fi analytics start-up i2e1 plans to use the funding from Omidyar Network, 3one4 Capital to scale its existing operations and launc...",
  },
];
const FAQContent = [
  {
    title: "Hey, I don’t understand PM WANI scheme, what is it?",
    content:
      'Don’t sweat it. I’ll take care of the explanation for you. <br/>Well, Prime Minister Wi-Fi Access Network Interface (PM WANI) scheme aims to provide last mile internet connectivity in India, while giving small businesses a scope to increase their income by re-selling their broadband connection. By doing so, small businesses become Public Data Offices or PDOs and they create a community of very happy, data rich Indians 😊<br /> Too complicated to understand? Try this version – here is what PM says about it:<br /><br /> <i> “The scheme would enable our small shopkeepers to provide Wi-Fi service. This will boost incomes as well as ensure our youth gets seamless internet connectivity. It will also strengthen our Digital India mission”</i><br/><div style="align-text:left"><p> - Prime Minister Narendra Modi</h1></p><br/> Still not sure?<br/> Okay,<br/> Buy WIOM and you will know how 😉. <a style="color:#F4693A; font-weight: bold" href="/pdo/order">Click here</a>',
  },
  {
    title: "So, what is a PDO exactly?",
    content:
      'A Public Data Office (PDO) can be any retail store who can re-sell existing internet bandwidth in the form of Wi-Fi plans to its customers with a WANI compliant hotspot. PDOs will in turn, be managed by PDO Aggregators or PDOA.<br/><br/> Go to this <a style="color:#F4693A; font-weight: bold" href="https://dot.gov.in/sites/default/files/2020_12_11%20WANI%20Framework%20Guidelines.pdf?download=1" target="_blank">link</a> to know the entire architecture of PM WANI',
  },
  {
    title: "What is a WANI compliant hotspot?",
    content:
      'Any hotspot that can give seamless internet access to a Wi-Fi user after verifying her identity, is able to store compliance related user logs and is able to allow the PDO to sell Wi-Fi plans to users, is a WANI compliant hotspot.</br></br> WIOM is also a WANI compliant hotspot. You can buy it from <a style="color:#F4693A; font-weight: bold" href="/pdo/order">here</a>. Please buy.',
  },
  {
    title: "And who can be a PDOA?",
    content:
      'If you are a company registered under the Companies Act, 2013, you can be a PDOA. Your registration process does not require any fee, it’s completely online and takes up to 7 days for any company to become a PDOA. You are required to register with DOT and cDOT Central registry to become a PDOA. Broadly, just follow these steps mentioned below:</br></br> 1. To become a Public Data Office Aggregator, any entity will have to register online on <a style="color:#F4693A; font-weight: bold" href="https://saralsanchar.gov.in" target="_blank">https://saralsanchar.gov.in/</a><br/><br/> 2. Post registration on Saral Sanchar, the PDOA will have to contact the Central Registry <a style="color:#F4693A; font-weight: bold" href="https://pmwani.cdot.in">(https://pmwani.cdot.in)</a> for certification of their system and application software. For PDOAs using WIOM device, we will help out you out in the entire process</br></br> Once you become a PDOA, your role will be to ensure that PDOs are operating with a WANI compliant hotspot.',
  },
  {
    title: "How does WIOM help out with WANI compliance?",
    content:
      'WIOM provides the technology to perform the authentication, authorisation and accounting of Wi-Fi users across PDOs.</br></br> WIOM hotspot device is end to end cloud managed. PDOs and PDOAs can monitor and do data accounting, create internet plans across PDOs from one central cloud-based web portal. The hotspot in itself is capable of authenticating and authorising users as per rules defined by the PDOAs and PDOs on the cloud portal. </br></br>  PDOs can get more details here - <a style="color:#F4693A; font-weight: bold" href="/pdo/home">www.wiom.in/pdo</a></br> PDOAs can get more details here –<a style="color:#F4693A; font-weight: bold" href="/partner/home"> www.wiom.in/pdoa</a>',
  },
  {
    title: "Hmmm, so is there any registration fee involved to become a PDOs?",
    content:
      "Good News! There is no registration or licence fee required to become a PDO.",
  },
  {
    title: "And can anyone become a PDO?",
    content:
      'Yup, anyone can become a PDO. All you need to do is, go to this <a style="color:#F4693A; font-weight: bold" href="/pdo/order"> link</a>, order your WIOM device, and set it up to become a PDO, with i2e1 as your registered PDOA. Yes, we just tried to sell you WIOM device. If you still aren’t convinced, read on.',
  },
  {
    title: "Is the WIOM set up complicated?",
    content:
      'Not at all! It’s a plug and play device, all you need to do is just connect it to your internet connection and you are good to go in just 2 minutes! Here are the set-up and registration <a style="color:#F4693A; font-weight: bold" href="/pdo/learning-center/how_to_guides"> guides</a>. Check them out now or whenever you receive your WOM device.</br></br> You will anyways get a hard copy of these with the product. Better to check it out when you receive the product. Again, you can order the device from <a style="color:#F4693A; font-weight: bold" href="/pdo/order">here</a> 😊 ',
  },
  {
    title: "Any other benefits of WIOM?",
    content:
      'If you are a retailer, you can get two-fold benefits with WIOM:</br></br> 1. WIOM helps small businesses to increase their income by selling their internet bandwidth and making money. Refer to the video <a style="color:#F4693A; font-weight: bold" href="/learn-more">here</a>. (Sorry for repeating this – couldn’t help it, plus it helps with SEO :P )</br></br> 2. You can also drive customer loyalty with smart marketing features of WIOM. Check out how –<a style="color:#F4693A; font-weight: bold" href="/pdo/home">here</a>',
  },
  {
    title: "Great, how do I buy the WIOM device?",
    content:
      'Favourite question 😊 Just go to <a style="color:#F4693A; font-weight: bold" href="/pdo/order">www.wiom.in/pdo/payment</a> to purchase your WIOM device',
  },
];
export default class LearningCenter extends Component {
  showComponent(ID) {
    headingCardContent.forEach((content) => {
      if (ID == content.scrollID) {
        var x = document.getElementById(content.scrollID);
        x.style.display = "block";
        document.getElementById(`button-${content.scrollID}`).style.filter =
          "grayscale(0%)";
      } else {
        document.getElementById(content.scrollID).style.display = "none";
        document.getElementById(`button-${content.scrollID}`).style.filter =
          "grayscale(100%)";
      }
    });
  }

  myHeaders = (site) => {
    let id = this.props.match.params.scrollId;
    let heads = headingCardContent.map((cardContent) => {
      return (
        <a
          className={"tile " + site + (id == cardContent.scrollID? " select": "")}
          href={`/${site}/learning-center/${cardContent.scrollID}`}
          //onClick={() => this.showComponent(cardContent.scrollID)}
          id={`button_${cardContent.scrollID}`}
          key={`button_${cardContent.scrollID}`}
        >
          <div className="dot">
            <img src={`${process.env.PUBLIC_URL}/svgs/lrncen/${cardContent.scrollID}.svg`} alt="logo" />
          </div>
          <span>{cardContent.title}</span>
        </a>
      );
    });

    return <div className="tile-container" >
      <div className="tiles">
        <img className="lrncen-bg" src={`${process.env.PUBLIC_URL}/pngs/lrncenbg.png`} />
        {heads}
      </div>
    </div>
  }

  cardHead = (id, head) => {
    return <div className="head">
      <div className="dot">
        <img src={`${process.env.PUBLIC_URL}/svgs/lrncen/${id}.svg`} alt="logo" />
      </div>
      <h1 style={{ fontSize: "2.5em", margin: "0" }}>{head}</h1>
    </div>
  }

  getBlogs = (site, id) => { 
    return <div className={"lc-card " + site} id="blogs">
      {this.cardHead(id, 'Blogs')}
      <div className="content">
        <div className="row">
          <div className="blog-card">
            <h1>A Digital Revolution across the next 500 million in India</h1>
            <img src={blogscolumn1}/>
            <h2>With a simple crowbar like apparatus, people of all ages and gender can balance up to 40 litres of water and carry it easily on bicycles, daily.</h2>
            <a style={{fontSize: '2em'}} href="/blog/A+Digital+Revolution+next+500+million+India">Read More</a>
          </div>
          
          <div className="blog-card">
            <h1>PM WANI to boost small businesses and connect India</h1>
            <img src={blogscolumn2}/>
            <h2>The tale of the common man in India has always been one of struggle and in-sufficiency. This became quite evident in the post Covid-19 world, when the divide between the privileged and the under-privileged got exposed by the pandemic</h2>
            <a style={{fontSize: '2em'}} href="/blog/PM+WANI+Small+Businesses+PDO">Read More</a>
          </div>
        </div>

        <div className="row">
          <div className="blog-card">
            <h1>Internet 1 Rupaiye ka, Munaafa 8 aane ka</h1>
            <h2>In my previous blog, I shared how i2e1 is enabling Wi-Fi at small paan shops where internet is available for 1 Rupee. 100+ such shops are live in South Delhi as part of TRAI initiative to increase internet penetration in India.</h2>
            <a href="https://www.linkedin.com/pulse/internet-1-rupaiye-ka-munaafa-8-aane-satyam-darmora/" target="_blank">Read More</a>
          </div>
          
          <div className="blog-card">
            <h1>Beer & Chai teaching growth hacks to i2e1</h1>
            <h2>“Let us catch up at Chaayos, South point mall?” is a very familiar statement often heard at our office. This is Nishit talking to someone over the phone and setting up his Gurgaon Meeting. All of Nishit’s meetings in Gurgaon happen at Chaayos and believe me he meets a ton of people every week</h2>
            <a href="https://www.linkedin.com/pulse/beer-chai-teaching-growth-hacks-i2e1-satyam-darmora/" target="_blank">Read More</a>
          </div>
        </div>
      </div>
    </div>
  }

  knowladgeSharing = (site, id) => {
    return <div className="lc-card" id="knowledge_sharing" >
      {this.cardHead(id, 'Knowledge Sharing')}
      <div className="content">
        <div className="row row-1">
          <div>
            <h1>How shop owners are benefiting from PM WANI</h1>
          </div>
          <YTWrapper for={site} url="https://www.youtube.com/watch?v=WsAzD_MVjQc" thumb={ `${process.env.PUBLIC_URL}/thumbs/kusumpur_pahadi_stories.png` }>
            Check out the stories in Kusum Pahadi, Delhi
          </YTWrapper>
        </div>

        <div className="row row-2">
          <YTWrapper for={site} url="https://www.youtube.com/watch?v=6bjRpmND8Vw&t=921s" thumb={ `${process.env.PUBLIC_URL}/thumbs/wifi_can_transform_lifes.png` }>
            <b>Founder speaks:</b> <span style={{color: "#6d6b6b"}}> Wi-Fi can transform lives</span>
          </YTWrapper>
          <YTWrapper for={site} url="https://www.youtube.com/watch?v=sy7y_0AjXes" thumb={ `${process.env.PUBLIC_URL}/thumbs/indias_first_pdo_is_live.png` }>
            <b>Chasing Dreams:</b> <span style={{color: "#6d6b6b"}}> India's First PDO is now Live!</span>
          </YTWrapper>
          <YTWrapper for={site} url="https://www.youtube.com/watch?v=sy7y_0AjXes" >
            <span style={{color: "#6d6b6b"}}>Upcoming Webinar...</span>
          </YTWrapper>
        </div>
      </div> 
    </div>
  }

  howToGuides = (site, id) => {
    return <div className="lc-card" id="how_to_guides">
      {this.cardHead(id, 'How to guides')}
      <div className="content">
        <div className="row">
          <div>
            <h2 style={{ margin: "0", color: "#6D6B6B" }}>Step 1</h2>
            <YTWrapper for={site} url="https://youtu.be/SE6pQjzMW6c"
              thumb={ `${process.env.PUBLIC_URL}/thumbs/install_your_wiom_inbox.png`}>
              Install your Wiom box
            </YTWrapper>
          </div>
          <div>
            <h2 style={{ margin: "0", color: "#6D6B6B" }}>Step 2</h2>
            <YTWrapper for={site} url="https://youtu.be/Xxz_p9Z8XsM"
              thumb={ `${process.env.PUBLIC_URL}/thumbs/installation_successful.png`}>
              Create your account
            </YTWrapper>
          </div>
        </div>

        <div className="row">
          <div>
            <h2 style={{ margin: "0", color: "#6D6B6B" }}>Step 3</h2>
            <YTWrapper for={site} url="https://youtu.be/NJMcoGvQmhc"
              thumb={ `${process.env.PUBLIC_URL}/thumbs/create_plans.png`}>
             Create Wi-Fi plans
            </YTWrapper>
          </div>
          <div>
            <h2 style={{ margin: "0", color: "#6D6B6B" }}>Step 4</h2>
            <YTWrapper for={site} url="https://youtu.be/UGB7clvANUU"
              thumb={ `${process.env.PUBLIC_URL}/thumbs/give_access.png`}>
              Grant internet access
            </YTWrapper>
          </div>
        </div>
      </div>
          <div
            style={{
              margin: "1rem",
              textAlign: "center",
              fontSize: "2rem",
              fontWeight: "bold",
              color: ((site == 'pdo' ? '#F4693A': '#0839FB')),
              textDecoration: "underline",
            }}
          >
            <a target="_blank" style={{ cursor: "pointer", color: 'inherit !important'}} href={`${process.env.PUBLIC_URL}/WIOM_Guide.pdf`}>Download PDF</a>
          </div>
        </div>
  }

  inNews = (site, id) => {
    return (
      <div className="lc-card" id="in-news">
        {this.cardHead(id, 'In News')}
        <div className="content">
          {newsdata.map((news) => {
            let rightC = null;
            if (news.heading) {
              rightC = <div div style = {{ margin: "1em" }}>
                <h1>{news.heading}</h1>
                <h2 style={{ fontWeight: "lighter" }}>{news.subheading}</h2>
                <a
                  style={{
                    fontSize: "1.5em",
                    color: "#F4693A",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                  href={news.link} target="_blank"
                >
                  Read more
                </a>
              </div>
            } else {
              rightC = <div>
                {news.image2}
                <a
                  style={{
                    fontSize: "1.5em",
                    color: "#F4693A",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                  href={news.link || "/pngs/dainik_bhaskar.png"} target="_blank"
                >
                  full view
                </a>
              </div>
            }
            
            return (
              <div className="news-card">
                <img src={news.image} alt="et"></img>
                {rightC}
              </div>
            );
          })}
        </div>
      </div>
    
    
    
    );
  };

  faqs = (site, id) => {
    return (
      <div className="lc-card" id="faqs">
        {this.cardHead(id, 'FAQs')}
        <div className="content">
          {FAQContent.map((value) => {
            return <Accordian title={value.title} content={value.content} />;
          })}
        <div className="FAQs-number">
          <h1>Any other questions?</h1>
          <h1>
            Just call on <a href="tel:8880322222" style={{ color: "#F4693A" }}>888-03-22222</a>
          </h1>
        </div>
        </div>
      </div>);
  }

  statusCertification = () => {
    return (<StatusCertification></StatusCertification>);
  }

  renderInnerContainer = (site) => {
    let id = this.props.match.params.scrollId;
    switch (id) {
      case 'blogs':
        return this.getBlogs(site, id);
      case 'knowledge_sharing':
        return this.knowladgeSharing(site, id);
      case 'how_to_guides':
        return this.howToGuides(site, id);
      case 'faqs':
        return this.faqs(site, id);
      case 'news':
        return this.inNews(site, id);
      case 'status_certification':
        return this.statusCertification();
      default:
        return this.getBlogs(site);
    }
  }

  render = () => {
    let site = this.props.match.params.for;
      console.log('inner container: ', this.props.match.params.scrollId, this.props.match.params.for);
    return (
      <div className="learning-center">
        <Header for={site} selected="learning-center" /> 
        {this.props.match.params.scrollId != 'status_certification' &&
          this.myHeaders(site)
        }
        {this.renderInnerContainer(site)}
        {this.props.match.params.scrollId != 'status_certification' && <Footer for={site} />}
      </div>
    );
  };
}
