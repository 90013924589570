import { React, useEffect } from "react";

import "./accordian-plan.scss";
import "./table/expandtable.scss";

import arrow from "../assets/media/plans/arrow.svg";

function AccordianPlan(props) {
  const { title, number, info } = props.data;

  useEffect(() => {
    document.getElementById(`Accordion-${number}`).click();
  }, []);

  const toggleAccordion = (e) => {
    let x = e.currentTarget;
    var y = document.getElementById(`panel-content-${number}`);
    var arrowSign = document.getElementById(`Arrow-${number}`);
    if (x.classList.toggle("open")) {
      y.style.height = `${y.scrollHeight}px`;
      arrowSign.style.transform = "rotate(90deg)";
      arrowSign.style.transition = "all 0.5s ease";
    } else {
      y.style.height = 0;
      arrowSign.style.transform = "rotate(-2.5deg)";
      arrowSign.style.transition = "all 0.5s ease";
    }
  };

  return (
    <>
      <td
        classname={`accordion-${number}`}
        id={`Accordion-${number}`}
        onClick={(e) => {
          toggleAccordion(e);
        }}
        style={{
          cursor: "pointer",
        }}
      >
        <tr className="plan-title" style={{ display: "flex" }}>
          <h1>{title}</h1>
          <img className="arrow" id={`Arrow-${number}`} src={arrow} />
        </tr>
      </td>
      <span className="panel" id={`panel-content-${number}`}>
        {info.map((value) => {
          return (
            <td>
              <tr className="plan-title">
                <h1 className="feature-heading">{value.feature}</h1>
              </tr>
              <tr>
                <img src={value.wiommagic} />
              </tr>
              <tr>
                <img src={value.wiomprime} />
              </tr>
            </td>
          );
        })}
      </span>
    </>
  );
}

export default AccordianPlan;
