import React, { Component } from "react";
import "./publishing.scss";
import YTWrapper from "./yt-video.js";

export default class Publishing extends Component {
  constructor() {
    super();
    this.smalldevice = document.body.clientWidth <= 540;
  }

  scroll(eve, type) {
    let markup = eve.currentTarget.closest('.column').querySelector('.markup');
    let div = markup.querySelector('div.top') || markup.querySelector('div:nth-child(2)');
    if (type == 'down') {
      div = div.nextElementSibling || markup.querySelector('div');
    } else {
      div = div.previousElementSibling || markup.querySelector('div:last-child');
    }
    
    markup.querySelectorAll('div').forEach(element => {
      element.classList.remove('top')
    });
    div.classList.add('top')
    div.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.loadJS('https://platform.twitter.com/widgets.js')
    }, 200);
  }

  loadJS = (file) => {
      var jsElm = document.createElement("script");
      jsElm.type = "application/javascript";
      jsElm.src = file;
      document.body.appendChild(jsElm);
  }

  setMarkupHeight = (e) => {
    if(this.smalldevice) {
      let target = e.currentTarget;
      let markup = target.querySelector('.markup');
      if(target.classList.toggle('open')) {
        markup.style['height'] = markup.scrollHeight + 'px';
      } else {
        markup.style['height'] = 0;
      }
    }
  }
  
  render() {
    let videos = [
      { thumb: 'indias_first_pdo_is_live.png', mota:'India\'s First PDO is now Live!', yt_link: "https://www.youtube.com/watch?v=sy7y_0AjXes" },
      { thumb: 'wifi_can_transform_lifes.png', mota:'Founder speaks: Wi-Fi can transform lives', yt_link: "https://www.youtube.com/watch?v=6bjRpmND8Vw&t=921s" },
      { thumb: 'kusumpur_pahadi_stories.png', mota:'Small businesses breaking barriers with i2e1', yt_link: "https://www.youtube.com/watch?v=WsAzD_MVjQc" },
      { thumb: 'learn_more.png', mota:'PM WANI to boost shopkeeper income, connect India', yt_link: "https://www.youtube.com/watch?v=An2aQGHbUjE&feature=youtu.be" }
    ];

    let articles = [
      { img: `${process.env.PUBLIC_URL}/pngs/articles_1.png`, link: 'https://telecom.economictimes.indiatimes.com/news/delhi-based-startup-i2e1-launches-first-pdo-under-pm-wani-initiative/80450032' },
      { img: `${process.env.PUBLIC_URL}/pngs/articles_2.png`, link: 'https://inc42.com/startups/i2e1-turns-kiranas-into-wi-fi-hotspots-can-it-take-on-cheap-4g-plans/' },
      { img: `${process.env.PUBLIC_URL}/pngs/articles_3.png`, link: '/blog/PM+WANI+Small+Businesses+PDO'},
      { img: `${process.env.PUBLIC_URL}/pngs/articles_4.png`, link: '/blog/A+Digital+Revolution+next+500+million+India'}
    ]
    let articleText = [
      { mota: 'Delhi-based startup i2e1 launches first PDO under PM-Wani initiative', chota: 'According to the company, the PDO which has been established in South Delhi\'s Kusumpur Pahadi area, will allow the area residents to access 50Mbps high-speed and unlimited internet at Rs 5 per day with no registration'},
      { mota: 'This Startup Wants To Turn Kiranas Into Wi-Fi Hotspots, But Will Cheap Mobile', chota:'We want to make internet access so affordable that people will pick a plan instead of a packet of chips or a chocolate bar one gets for INR 5,” says Satyam Darmora, founder of the internet startup i2e1 (Information to everyone), explaining why all and sundry will pay for the company’s Wi-Fi hotspot service, to be available even at a nukkad tea shop'},
      { mota: 'PM WANI to boost small businesses and connect India', chota: 'The tale of the common man in India has always been one of struggle and in-sufficiency. This became quite evident in the post Covid-19 world, when the divide between the privileged and the under-privileged got exposed by the pandemic'},
      { mota: 'A Digital Revolution across the next 500 million in India', chota:'With a simple crowbar like apparatus, people of all ages and gender can balance up to 40 litres of water and carry it easily on bicycles, daily'}
    ]

    let articlesMarkup = [];
    articles.forEach((article, index) => {
      articlesMarkup.push(
        <div className="card_publishing article">
          <img src={article.img} />
          <h1>{articleText[index].mota}</h1>
          <span>{articleText[index].chota}</span>
          <a href={ article.link } target="_blank">Read More</a>
        </div>
      )
    });

    let videosMarkup = [];
    videos.forEach(video => {
      videosMarkup.push(
        <div className="card_publishing">
          <YTWrapper for={this.props.for} url={video.yt_link}
           thumb={ `${process.env.PUBLIC_URL}/thumbs/${video.thumb}` } >
            <h1>{video.mota}</h1>
          </YTWrapper>
        </div>
      );
    })

    let scroller = <div className="scroller hide-in-small">
      <div onClick={(event) => this.scroll(event, 'up') }></div>
      <div onClick={ (event) => this.scroll(event, 'down') }></div>
    </div>
    return (
      <div className="main_publishing">
        <div className="column" onClick={(e) => { this.setMarkupHeight(e) }}>
          <h1 >Articles</h1>
          <div className="markup">
            {articlesMarkup}
          </div>
          {scroller}
        </div>
        <div className="column" onClick={(e) => { this.setMarkupHeight(e) }}>
          <h1>Videos</h1>
          <div className="markup">
            {videosMarkup}
          </div>
          {scroller}
        </div>
        <div className="column" onClick={(e) => { this.setMarkupHeight(e) }}>
          <h1>Social Feed</h1>
          <div className="markup" style={{maxHeight: '40rem', overflowY: 'auto'}}>
            <a className="twitter-timeline" data-lang="en" data-dnt="true" data-theme="light" href="https://twitter.com/i2e1official?ref_src=twsrc%5Etfw">Tweets by i2e1official</a> 
          </div>
        </div>
      </div>
    );
  }
};
